import Repository from "./Repository";

const ADD_ENTRY = "addDocument";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "getDocuments";
const DOWNLOAD= "download";

export default {
  addDocument(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editDocument(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteDocument(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getDocument(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
  downloadfile(payload) {
    return Repository.post(transformRoute(DOWNLOAD), payload);
  },
};

const transformRoute = (route) => {
  return `/documents/${route}`;
};
