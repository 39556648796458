import state from "../auth/state";

export default {
  UPDATE_CATEGORY_INFO(state, payload) {
    state.categories = payload;
  },
  SELECT_SUBCATEGORY_BY_ID(state, payload){
    state.subcategoriesid = payload;
  },
  UPDATE_SUBCATEGORY_INFO(state, payload) {
    state.updatesubcategories = payload;
  },
  GET_SUBCATEGORY_INFO(state, payload) {
    state.subcategories = payload;
  },
  UPDATE_BANNER_INFO(state, payload) {
    state.bannerState = payload;
  },

  // Documents
  GET_DOCUMENTS_INFO(state,payload){
    state.documents = payload;
  },
  DOWNLOAD_DOCUMENT(state,payload){
    state.downloaddocument = payload;
  },
  UPDATE_DOCUMENTS_INFO(state,payload){
    state.updatedocuments = payload;
  },
  //   Products
  UPDATE_PRODUCTS_INFO(state, payload) {
    state.productState = payload;
  },
  UPDATE_PRODUCTS_INFO_BY_ID(state, payload) {
    state.productStateId = payload;
  },
  UPDATE_MAIN_All_PRODUCT_INFO(state, payload) {
    state.mainProductState = payload;
  },
  UPDATE_SINGLE_PRODUCT_INFO(state, payload) {
    state.singleProductState = payload;
  },
  UPDATE_SINGLE_PRODUCT_INFOO(state, payload) {
    state.singleProductStatee = payload;
  },
  UPDATE_TAXES_INFO(state, payload) {
    state.taxState = payload;
  },
  UPDATE_COLORVARIANTS_INFO(state, payload) {
    state.colorVariantState = payload;
  },
  UPDATE_SIZEVARIANTS_INFO(state, payload) {
    state.sizeVariantState = payload;
  },
  UPDATE_CARTS_INFO(state, payload) {
    state.cartState = payload;
  },
  UPDATE_ORDERS_INFO(state, payload) {
    state.orderState = payload;
  },
  UPDATE_COUPONS_INFO(state, payload) {
    state.couponState = payload;
  },
  PRODUCT_FILTER_CATS(state, payload) {
    state.productFilterCats = paylaod;
  },
  UPDATE_DISPATCH_INFO(state, payload) {
    state.dispatchState = payload;
  },
  UPDATE_ADDRESS_INFO(state, payload) {
    state.addressState = payload;
  },
  UPDATE_singleProductAdminState(state, payload) {
    state.singleProductAdminState = payload;
  },
  UPDATE_ALL_INFO_PRODUCTS(state, payload) {
    state.allDetailsOfProduct = payload;
  },
  UPDATE_SIMILAR_PRODUCTS(state, payload) {
    state.similarProducts = payload;
  },
  UPDATE_PRODUCT_DATA(state, payload) {
    state.productData = payload;
  },
  GET_PRODUCT_BY_CATEGORY(state, payload) {
    state.productbycategory = payload;
  },
  GET_PRODUCT_BY_SUBCATEGORY(state, payload) {
    state.productbysubcategory = payload;
  },
  GET_PRODUCT_BY_STATUS(state, payload) {
    state.productbystatus = payload;
  },
  PRODUCT_OVERVIEW(state, payload) {
    state.productoverview = payload;
  },
  ENQ_PRODUCT(state, payload) {
    state.enqprd = payload;
  },

   //   Products Category
   GET_PRODUCT_CATEGORY_INFO(state,payload){
    state.productCategory = payload;
   },
   EDIT_PRODUCT_CATEGORY_BY_ID(state,payload){
    state.productCategorybyId = payload;
   },

   //Product sub Category

   EDIT_PRODUCT_SUB_CATEGORY_BY_ID(state,payload){
    state.productsubCategorybyId = payload;
   },
   SELECT_PRODUCT_SUBCATEGORY_BY_ID(state,payload){
    state.selectproductsubCategorybyId = payload;
   },
   SELECT_PRODUCT_SUBCATEGORY_BY_IDS(state,payload){
    state.selectproductsubCategorybyIds = payload;
   },

   //Catalogue

   ADD_CATALOGUE(state,payload){
    state.catalogue = payload;
   },
   GET_CATALOGUE(state,payload){
    state.getcatalogue = payload;
   },
   UPDATE_Catalogue(state,payload){
    state.updatecatalogue = payload;
   },
   GET_MANY_CATALOGUE(state,payload){
    state.getmanycatalogue = payload;
   },
   DOWNLOAD_CATALOGUE(state,payload){
    state.downloadcatalogue = payload;
   },

   //CONTACTS

   GET_CONTACT(state,payload){
    state.getcontact = payload;
   },
   UPDATE_CONTACT(state,payload){
    state.updatecontact = payload;
   },

   //NEWS

   GET_NEWS(state,payload){
    state.getnews = payload;
   },

   //Event

   GET_EVENT(state,payload){
    state.getevent = payload;
   },
   
   PAST_EVENT(state,payload){
    state.pastevent = payload;
   },
   
   UP_EVENT(state,payload){
    state.upevent = payload;
   },
   UPDATE_EVENT(state,payload){
    state.updateevent = payload;
   },
   UPLOAD_EVENT_IMAGES(state,payload){
    state.uploadimages = payload;
   },

   //Project Category

   GET_PROJECT_CATEGORY(state,payload){
    state.getprojectcat= payload;
   },
   EDIT_PROJECT_CATEGORY(state,payload){
    state.editprojectcat= payload;
   },

   //Project

   GET_PROJECTS(state,payload){
    state.getprojects= payload;
   },
   ADD_PROJECT_IMAGES(state,payload){
    state.projectid= payload;
   },
   GET_SINGLE_PROJECT(state,payload){
    state.singleproject= payload;
   },
   ADD_PRODUCT_DETAIL(state,payload){
    state.addproductdetailid= payload;
   },
   UPDATE_PROJECT(state,payload){
    state.updateproject= payload;
   },

   //contact us
   CONTACT_US(state,payload){
    state.contactus= payload;
   },

   //Blogs

   GET_BLOGS(state,payload){
    state.blogs= payload;
   },
   UPDATE_BLOG(state,payload){
    state.updateblogs= payload;
   },
   BLOG_DETAIL(state,payload){
    state.blogdetails= payload;
   },
   BLOG_URL(state,payload){
    state.blogurl= payload;
   },

   //blog category

   GET_BLOG_CATEGORY(state,payload){
    state.blogcategory= payload;
   },

   UPDATE_BLOG_Category(state,payload){
    state.updateblogcategory= payload;
   },
};
