export default {
  SET_Category(state, payload) {
    state.category = payload;
  },
  SET_SELECT_USER(state, payload) {
    state.SelectedUser = payload;
  },
  GET_Category(state, payload) {
    state.category = payload;
  },
  UPDATE_Category(state, payload) {
    state.UpdateCategory = payload;
  },
};
