export default {
  // Home Page
  categories: [],
  subcategories: [],
  documents:[],
  updatedocuments:[],
  downloaddocument:{},
  updatesubcategories:[],
  subcategoriesid:[],
  bannerState: [],
  productState: [],
  mainProductState: [],
  singleProductAdminState: [],
  //
  colorVariantState: [],
  sizeVariantState: [],
  taxState: [],
  singleProductState: [],
  singleProductStatee: [],
  //
  cartState: [],
  orderState: [],
  couponState: [],
  productFilterCats: [],
  dispatchState: [],
  addressState: [],
  allDetailsOfProduct: [],
  similarProducts: [],
  // Otheres:
  productData: [],
  productCategory:[],
  productCategorybyId:[],
  productsubCategorybyId:[],
  productsubCategorybyIds:[],
  productStateId:[],
  productbycategory:[],
  productbysubcategory:[],
  productbystatus:[],
  selectproductsubCategorybyIds:[],
  productoverview:[],
  enqprd:[],
  //catalogue
  getcatalogue:[],
  selectcataloguebyid:[],
  updatecatalogue:[],
  getmanycatalogue:[],
  downloadcatalogue:[],

  //contact
  updatecontact:[],
  getcontact:[],

  //news

  getnews:[],

  //event

  getevent:[],
  pastevent:[],
  upevent:[],
  updateevent:[],
  uploadimages:[],
//project

  getprojectcat:[],
  projectid:[],
  singleproject:[],
  addproductdetailid:[],
  updateproject:[],

  //blogs

blogs:[],
updateblogs:[],
blogdetails:[],
blogurl:"",

//blog category

blogcategory:[],
updateblogcategory:[]
};


