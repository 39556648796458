import API from "../../apis";

export default {
  addCollection({ commit }, payload = { limit: 10, page: 1 }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.category.addCollection(payload);

        commit("SET_Category", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.auth.addUser(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateUserDetails({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.auth.UpdateUserDetails(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  },

  getCategories({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.category.getCategories(payload);
        commit("GET_Category", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  },
  editCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.category.editCategory(payload);
        commit("UPDATE_Category", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  },
  deleteCollection({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.category.deleteCollection(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  },
};
