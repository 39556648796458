import Repository from "./Repository";

const ADD_ENTRY = "addcategory";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "getcategory";

export default {
  addCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editCategory(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getCategories(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/category/${route}`;
};
