import API from "../../apis";

export default {
  addDownloader({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.downloader.add(payload);

        commit("SET_DOWNLOADER", data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
 
  getAll({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await  API.downloader.getAll(payload);
        commit("GET_DOWNLOADER", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  },
  
};
