import API from "../../apis";

export default {
  GetUsers({ commit }, payload = { limit: 10, page: 1 }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.auth.GetUsers(payload);

        commit("SET_USERS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.auth.addUser(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  loginWithPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.auth.loginWithPassword(payload);
        localStorage.setItem("token", data.data.token);
        commit("UPDATE_USER_INFO", data.data.user);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  logout({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.auth.logout(payload);

        await commit("logout", null);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateUserDetails({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.auth.UpdateUserDetails(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
