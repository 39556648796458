import Repository from "./Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";

export default {
  addNews(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editNews(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteNews(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getNews(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/news/${route}`;
};
