import Repository from "./Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const GET_PRODUCT_COLORS = "getPoductsColorVariants";

export default {
  getAllCollection(payload) {
    return Repository.get(transformRoute(GET_ENTRY), payload);
  },
  getPoductsColorVariants(payload) {
    return Repository.post(transformRoute(GET_PRODUCT_COLORS), payload);
  },
  addOneCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editOneCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteOneCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/colorVariant/${route}`;
};
