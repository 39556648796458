import Repository from "./Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const GET_ALL_POPULATED_DETAILS = "GetWithColorAndProduct";
const SIMILAR_ENTRY = "similarProds";
const GET_PRODUCT_ALLsIZE = "getPoductsSizeVariants";

export default {
  getAllCollection(payload) {
    return Repository.get(transformRoute(GET_ENTRY), payload);
  },
  getPoductsSizeVariants(payload) {
    return Repository.post(transformRoute(GET_PRODUCT_ALLsIZE), payload);
  },
  getAllPopulatedDetails(payload) {
    return Repository.get(transformRoute(GET_ALL_POPULATED_DETAILS), payload);
  },
  addOneCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editOneCollection(payload) {
    console.log("inapisa");
    console.log(payload, "in apis");
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteOneCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  similarProductCollection(payload) {
    console.log(payload);
    return Repository.get(transformRoute(SIMILAR_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/sizeVariant/${route}`;
};
