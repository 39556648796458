import Repository from "./Repository";

const ADD_ENTRY = "addsubcategory";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "getsubcategory";
const GET_ENTRY_BY_ID = "getsubcategorybycatid"
const GET_ENTRY_BY_IDS = "getsubcategorybymanycatid"

export default {
  addCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  updateCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteCollection(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getSubcategory(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
  getSubcategoryById(payload) {
    return Repository.post(transformRoute(GET_ENTRY_BY_ID), payload);
  },
  getSubcategoryByIds(payload) {
    return Repository.post(transformRoute(GET_ENTRY_BY_IDS), payload);
  },
};

const transformRoute = (route) => {
  return `/productsubcategory/${route}`;
};
