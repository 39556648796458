import Repository from "./Repository";

const REGISTER = "register";
const login="loginWithPassword"
const getUsers = "getUsers";
const addUser = "addUser";
const LOGOUT= "logout";
const UpdateUserDetails = "UpdateUserDetails";

export default {
  signupWithPassword(payload) {
    return Repository.post(transformRoute(REGISTER), payload);
  },
  loginWithPassword(payload){
    return Repository.post(transformRoute(login), payload);
  },
  GetUsers(payload) {
    return Repository.post(transformRoute(getUsers), payload);
  },
  addUser(payload) {
    return Repository.post(transformRoute(addUser), payload);
  },
  UpdateUserDetails(payload) {
    return Repository.post(transformRoute(UpdateUserDetails), payload);
  },
  logout(payload) {
    return Repository.post(transformRoute(LOGOUT), payload);
  },
};

const transformRoute = (route) => {
  return `/auth/${route}`;
};
