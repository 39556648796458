import Vue from "vue";
import VueRouter from "vue-router";
// import AdminRouter from "@/views/admin/routes/Index.js";
// import AdminRouter from "@/views/admin/routes/Index";
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: { name: "rewards" },
      children: [
        {
          path: "",
          name: "rewards",
          component: () => import("@/views/eurobondreward/EurobondReward.vue"),
        },
        {
          path: "privacy-policy",
          name: "privacy-policy",
          component: () => import("@/views/privacypolicy/PrivacyPolicy.vue"),
        },
      ],
    },

    //dark navbar
    // {
    //   path: "/channel",
    //   name: "maindark",
    //   component: () => import("@/main/MainDark.vue"),
    //   redirect: "channel-discussion",
    //   children: [],
    // },
  ],
});
export default router;
