import Repository from "./Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";

export default {
  addupcomingEvent(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  editupcomingEvent(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteupcomingEvent(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getupcomingEvent(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/upcomingevent/${route}`;
};
