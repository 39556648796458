export default {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_SELECT_USER(state, payload) {
    state.SelectedUser = payload;
  },
  SET_USER_DATA(state, payload) {
    state.userData = payload;
  },
  UPDATE_USER_INFO(state, payload) {
    state.userinfo = payload;
  },
  logout(state, payload){
    state.logout = payload;
  }
};
