import auth from "./auth";
import Category from "./Category";
import Subcategory from "./Subcategory";
import Products from "./Products";
import colorVariant from "./colorVariant";
import Taxes from "./Tax";
import sizeVariant from "./sizeVariant";
import Documents from "./Documents";
import ProductCategory from "./ProductCategory";
import ProductSubcategory from "./ProductSubcategory";
import catalogue from "./catalogue";
import Downloader from "./Downloaders";
import ContactDetails from "./ContactDetails";
import news from "./news";
import event from "./event";
import upcomingevent from "./upcomingevent";
import enquire from "./enquire";
import EventRegister from "./EventRegister";
import ProjectCategory from "./ProjectCategory";
import Project from "./Project";
import ContactUs from "./ContactUs";
import Blog from "./blog";
import blogCategory from "./blogCategory";
import EuroReward from "./EuroReward";

export default {
  auth: auth,
  EuroReward: EuroReward,
  enquire: enquire,
  blogCategory: blogCategory,
  Blog: Blog,
  Project: Project,
  ContactUs: ContactUs,
  ProjectCategory: ProjectCategory,
  EventRegister: EventRegister,
  category: Category,
  contactDetail: ContactDetails,
  downloader: Downloader,
  catalogue: catalogue,
  document: Documents,
  subcategory: Subcategory,
  productSubCategory: ProductSubcategory,
  product: Products,
  Tax: Taxes,
  Event: event,
  upcomingevent: upcomingevent,
  news: news,
  sizeVariant,
  colorVariant: colorVariant,
  productCategory: ProductCategory,
};
