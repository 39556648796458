import Repository from "./Repository";

const ADD_ENTRY = "add";
const DELETE_ENTRY = "delete";
const UPDATE_ENTRY = "update";
const GET_ENTRY = "get";
const UPLOAD_ENTRY = "pastimage"

export default {
  addEvent(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  uploadImage(payload) {
    return Repository.post(transformRoute(UPLOAD_ENTRY), payload);
  },
  editEvent(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
  deleteEvent(payload) {
    return Repository.post(transformRoute(DELETE_ENTRY), payload);
  },
  getEvent(payload) {
    return Repository.post(transformRoute(GET_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/event/${route}`;
};
